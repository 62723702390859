import React from "react";
import Layout from "../../components/common/Layout";
import Section from "../../components/common/Section";
import SEO from "../../components/common/SEO";
import { H1, P } from "../../components/common/Typography";

const TITLE = "Αυτοσχεδιασμός";

export default function Page() {
  return (
    <Layout>
      <SEO title={TITLE} />

      <Section.Main>
        <H1>{TITLE}</H1>
        <P>
          Τα πάντα είναι χορός! Από την ακινησία μέχρι και την πιο μικρή ή
          μεγάλη κίνηση, το σώμα μας χορεύει ενστικτωδώς.
        </P>
        <P>
          Ο αυτοσχεδιασμός είναι η αυθόρμητη δημιουργία της κίνησης, χωρίς
          περιορισμούς, χωρίς σωστό και λάθος, χωρίς αυστηρές δομές. Μέσα απ'
          αυτόν θα εξερευνήσουμε το σώμα μας, το χώρο αλλά και τις σχέσεις με τα
          άλλα άτομα γύρω μας. Το μάθημα προσφέρει όλα τα απαραίτητα εργαλεία
          που χρειάζεται κάποιος για να ξεκλειδώσει το σώμα και να του επιτρέψει
          να αναλύσει το μηχανισμό της κίνησης, ενώ ταυτόχρονα βελτιώνει και την
          αυτοπεποίθησή του.
        </P>
        <P>
          Ο αυτοσχεδιασμός δεν αφορά μόνο τη δημιουργία νέας κίνησης αλλά και
          την αναθεώρηση των συνηθειών που έχει το σώμα ενός χορευτή και δίνει
          λύσεις ώστε να τις αποχωριστεί και να προχωρήσει παρακάτω.
        </P>
      </Section.Main>
    </Layout>
  );
}
